import _ from 'underscore';
import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';

@Component({
    selector: '.a-audio-menu'
})

export class AudioMenuComponent extends ComponentBase<HTMLElement> {
    
    private _audioTracks:  { enabled: boolean, language: string, id: string }[] = null;
    private _selectedAudioTrack: string = null;
    private _textTracks: { id: string, mode: 'disabled' | 'hidden' | 'showing', kind: string, label: string, language: string }[] = null;
    private _selectedTextTrack: string = null;
    private _selectedTextTrackLanguage: string = null;

    @Input('locales', { isJSON: true })
    private _locales: { code: string, name: string }[] = null;

    @ChildRef('.a-audio-menu__main-menu')
    private _mainMenu: HTMLDivElement = null;
    @ChildRef('.a-audio-menu__subtitle-menu')
    private _subtitlesMenu: HTMLDivElement = null;
    @ChildRef('.a-audio-menu__audio-menu')
    private _audioMenu: HTMLDivElement = null;

    @ChildRef('.a-audio-menu__subtitle-menu-items')
    private _subtitleMenuListElement: HTMLUListElement = null;
    @ChildRef('.a-audio-menu__audio-menu-items')
    private _audioMenuListElement: HTMLUListElement = null;

    @ChildRef('.a-audio-menu__audio-menu-title-container')
    private _subtitleMenuBack: HTMLDivElement = null;
    @ChildRef('.a-audio-menu__subtitle-menu-title-container')
    private _audioMenuBack: HTMLDivElement = null;

    @ChildRef('.a-audio-menu__main-menu-subtitle-value')
    private _subtitleValue: HTMLDivElement = null;
    @ChildRef('.a-audio-menu__main-menu-audio-value')
    private _audioValue: HTMLDivElement = null;

    public constructor(node: HTMLElement) {
        super(node);
    }

    @ClickListener('.a-audio-menu__main-menu-subtitle')
    public onSubtitlesClicked(e: MouseEvent): void {
        this.showSubtitlesMenu();
    }
    @ClickListener('.a-audio-menu__main-menu-audio')
    public onAudioClicked(e: MouseEvent): void {
        this.showAudioMenu();
    }

    public get selectedAudioTrack(): string {
        return this._selectedAudioTrack;
    }

    public set selectedAudioTrack(value:string) {
        this._selectedAudioTrack = value;
    }

    public get selectedTextTrack(): string {
        return this._selectedTextTrack;
    }

    public set selectedTextTrack(value:string) {
        this._selectedTextTrack = value;
    }

    public selectTextTrackLanguage(language: string): void {
        if (this._subtitleMenuListElement) {
            const itemElement: HTMLLIElement = this._subtitleMenuListElement.querySelector(`li[data-language="${language}"]`);        
            if (itemElement) {
                itemElement.click();
            }
        }
    }

    public get selectedTextTrackLanguage(): string {
        return this._selectedTextTrackLanguage;
    }

    private showMainMenu(): void {
        this._mainMenu.classList.add('a-audio-menu__main-menu--show');
        this._subtitlesMenu.classList.remove('a-audio-menu__subtitle-menu--show');
        this._audioMenu.classList.remove('a-audio-menu__audio-menu--show');
    }

    private showSubtitlesMenu(): void {
        this._mainMenu.classList.remove('a-audio-menu__main-menu--show');
        this._audioMenu.classList.remove('a-audio-menu__audio-menu--show');
        this._subtitlesMenu.classList.add('a-audio-menu__subtitle-menu--show');
    }

    private showAudioMenu(): void {
        this._mainMenu.classList.remove('a-audio-menu__main-menu--show');
        this._subtitlesMenu.classList.remove('a-audio-menu__subtitle-menu--show');
        this._audioMenu.classList.add('a-audio-menu__audio-menu--show');
    }  

    private show(): void {
        this.showMainMenu();
        this.addClass('a-audio-menu--show');
    }

    public hide(): void {
        this.showMainMenu();
        this.removeClass('a-audio-menu--show');
    }

    public toggle(): void {
        if (this._node.classList.contains('a-audio-menu--show')) {
            this.hide();       
        } 
        else {
            this.show();
        }
    }

    public addAudioTracks(value: { enabled: boolean, language: string , id: string }[]):void {
        this._audioTracks = value;
        const enabledTrack: { language: string , id: string } = value.find(audioTrack => audioTrack.enabled);
        this._selectedAudioTrack = enabledTrack?.id || null;
        // if (this._audioTracks.length > 1) {
            this._audioValue.innerHTML = this.getLocale(enabledTrack?.language) || USER_LOCALE.disabled;
            this.setAudioMenu();
            this.addClass('a-audio-menu__main-menu-subtitle--show', '.a-audio-menu__main-menu-subtitle');
        // }
    }

    public addTextTracks(value: { id: string, mode: 'disabled' | 'hidden' | 'showing', kind: string, label: string, language: string }[]): void {
        this._textTracks = value.filter(textTrack => textTrack.kind === 'subtitles');
        const enabledTrack: { id: string, label: string, language: string } = value.find(textTrack => textTrack.mode === 'showing');  
        this._selectedTextTrack = enabledTrack?.label || null;
        this._selectedTextTrack = enabledTrack?.language || null;
        // if (this._textTracks.length > 0) {
            this._subtitleValue.innerHTML = this.getLocale(enabledTrack?.label) || USER_LOCALE.disabled;
            this.setSubtitlesMenu();
            this.addClass('a-audio-menu__main-menu-audio--show', '.a-audio-menu__main-menu-audio');
        // }
        
    }

    private setAudioMenu(): void {
        // Add back navigation
        this._audioMenuBack.addEventListener('click', () => {
            this.showMainMenu();
        });

        this._audioTracks.forEach(audioTrack => {
            const audioElement: HTMLLIElement = document.createElement('li');
            audioElement.classList.add('a-audio-menu__audio-menu-item');
            audioElement.innerHTML = this.getLocale(audioTrack.language);
            if (audioTrack.id.includes('(description)')) {
                audioElement.innerHTML += ` (AD)`;
            }
            audioElement.dataset.id = audioTrack.id;            
            if (audioTrack.enabled) {
                audioElement.classList.add('a-audio-menu__audio-menu-item--selected');
            }
            audioElement.addEventListener('click', () => {
                this._selectedAudioTrack = audioTrack.id;
                this._audioValue.innerHTML = this.getLocale(audioTrack.language);
                if (audioTrack.id.includes('(description)')) {
                    this._audioValue.innerHTML += ` (AD)`;
                }
                this.dispatchCustomEvent('audio-track-changed');
                this.hide();
                this.updateAudioMenu();
            });
            this._audioMenuListElement.appendChild(audioElement);
        });
    }

    private setSubtitlesMenu(): void {
        // Add back navigation
        this._subtitleMenuBack.addEventListener('click', () => {
            this.showMainMenu();
        });

        this._textTracks.forEach(textTrack => {
            const textElement: HTMLLIElement = document.createElement('li');
            textElement.classList.add('a-audio-menu__subtitle-menu-item');
            textElement.innerHTML = this.getLocale(textTrack.label);
            textElement.dataset.id = textTrack.label;
            textElement.dataset.language = textTrack.language;
            if (textTrack.mode === 'showing') {
                this._selectedTextTrack = textTrack.label;
                this._selectedTextTrackLanguage = textTrack.language;
                textElement.classList.add('a-audio-menu__subtitle-menu-item--selected');
            }
            textElement.addEventListener('click', () => {
                this._selectedTextTrack = textTrack.label;
                this._selectedTextTrackLanguage = textTrack.language;
                this._subtitleValue.innerHTML = this.getLocale(textTrack.label);
                this.dispatchCustomEvent('text-track-changed');
                this.hide();
                this.updateSubtitlesMenu();
            });
            this._subtitleMenuListElement.appendChild(textElement);
        });

        const disabledTextElement: HTMLLIElement = document.createElement('li');
            disabledTextElement.classList.add('a-audio-menu__subtitle-menu-item');
            disabledTextElement.innerHTML = USER_LOCALE.disabled;
            disabledTextElement.dataset.id = 'disabled';
            if (!this._selectedTextTrack) {
                disabledTextElement.classList.add('a-audio-menu__subtitle-menu-item--selected');
            }
            disabledTextElement.addEventListener('click', () => {
                this._selectedTextTrack = 'disabled';
                this._selectedTextTrackLanguage = null;
                this._subtitleValue.innerHTML = USER_LOCALE.disabled;
                this.dispatchCustomEvent('text-track-changed');
                this.hide();
                this.updateSubtitlesMenu();
            });
            this._subtitleMenuListElement.appendChild(disabledTextElement);
    }

    private updateAudioMenu(): void {
        this._audioMenuListElement.querySelectorAll('li').forEach(audioElement => {
            audioElement.classList.remove('a-audio-menu__audio-menu-item--selected');
            if (audioElement.dataset.id === this._selectedAudioTrack) {
                audioElement.classList.add('a-audio-menu__audio-menu-item--selected');
            }
        });

        this._audioTracks.forEach(audioTrack => {
            audioTrack.enabled = false;
            if (audioTrack.id === this._selectedAudioTrack) {
                audioTrack.enabled = true;
            }
        });
    }

    private updateSubtitlesMenu(): void {
        this._subtitleMenuListElement.querySelectorAll('li').forEach(textElement => {
            textElement.classList.remove('a-audio-menu__subtitle-menu-item--selected');
            if (textElement.dataset.id === this._selectedTextTrack) {
                textElement.classList.add('a-audio-menu__subtitle-menu-item--selected');
            }
        });

        this._textTracks.forEach(textTrack => {
            textTrack.mode = 'disabled';
            if (textTrack.label === this._selectedTextTrack) {
                textTrack.mode = 'showing';
            }
        });
    }

    private getLocale(value: string): string {
        const name: string = ((this._locales || []).find(l => l.code == value) || {}).name;
        return name || value;
    }

    public unload(): void {
        while (this._subtitleMenuListElement.childNodes.length > 1) {
            this._subtitleMenuListElement.removeChild(this._subtitleMenuListElement.lastChild);
        }
        while (this._audioMenuListElement.childNodes.length > 1) {
            this._audioMenuListElement.removeChild(this._audioMenuListElement.lastChild);
        }
        this._audioTracks = null;
        this._selectedAudioTrack = null;
        this._textTracks = null;
        this._selectedTextTrack = null;
        this._selectedTextTrackLanguage = null;
    }

    public dispose(): void {
        this.hide();
        super.dispose();
    }
}